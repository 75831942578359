// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-fleet-js": () => import("/Users/baharehadham/Documents/Projects/kings-valley-paving/src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-index-js": () => import("/Users/baharehadham/Documents/Projects/kings-valley-paving/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("/Users/baharehadham/Documents/Projects/kings-valley-paving/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-story-js": () => import("/Users/baharehadham/Documents/Projects/kings-valley-paving/src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

exports.data = () => import("/Users/baharehadham/Documents/Projects/kings-valley-paving/.cache/data.json")

