/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/*
 *
 * Urgent polyfills (needed before first render)
 *
 */

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
  }

  // Babel-polyfill for IE (includes everything except fetch); load immediately on this site because of the complete failure to render in IE
  if (!loadjs.isDefined(`babel-polyfill`)) {
    if (typeof window.Symbol === `undefined`) {
      loadjs(
        `https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.0.0/polyfill.min.js`,
        `babel-polyfill`
      )
    }
  }
}

/*
 *
 * Non-urgent polyfills and scripts (needed after first render)
 *
 */

import loadjs from 'loadjs'

export const onInitialClientRender = () => {
  // A11Y: Detect keyboard vs. mouse vs. touch input (for focus styling)
  if (!loadjs.isDefined(`what-input`)) {
    loadjs(
      `https://cdnjs.cloudflare.com/ajax/libs/what-input/5.0.5/what-input.min.js`,
      `what-input`
    )
  }

  // GSAP for site-wide animations
  if (!loadjs.isDefined(`gsap`)) {
    loadjs(
      [
        `https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.1/TweenLite.min.js`,
        `https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.1/plugins/CSSPlugin.min.js`,
        `https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.1/TimelineLite.min.js`
      ],
      `gsap`
    )
  }

  // GSAP's scrollToPlugin for sitewide smooth-scrolling
  if (!loadjs.isDefined(`scrollToPlugin`)) {
    loadjs(
      `https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.0/plugins/ScrollToPlugin.min.js`,
      `scrollToPlugin`
    )
  }

  // Google Analytics + Ads (skip on localhost)
  if (window.location.hostname !== 'localhost') {
    // Google Analytics
    loadjs(`https://www.googletagmanager.com/gtag/js?id=G-QEK3JDGYM5`, () => {
      
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag(`js`, new Date())

      // Google Analytics
      gtag(`config`, `G-QEK3JDGYM5`)

      // Google Ads (piggyback on the global setup above)
      // See: https://support.google.com/google-ads/answer/6095883?co=ADWORDS.IsAWNCustomer%3Dtrue
      gtag(`config`, `AW-797474007`)

      // Google Ads phone snippet
      gtag('config', '/SOWoCIX71YkBENf5ofwC', {
        phone_conversion_number: '1.844.857.5464'
      })
    })

    // Google Analytics (using ga-lite to allow caching)
    // See: https://github.com/jehna/ga-lite
    // if (!loadjs.isDefined(`ga-lite`)) {
    // loadjs(
    // `https://cdn.jsdelivr.net/npm/ga-lite@2/dist/ga-lite.min.js`,
    // `ga-lite`,
    // () => {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id#automatic_cookie_domain_configuration
    // galite('create', 'UA-126914975-1', 'auto') // auto prevents tracking on localhost
    // galite('send', 'pageview')

    // See: https://github.com/jehna/ga-lite#onunload-tracking
    // window.addEventListener('unload', () => {
    // galite('send', 'timing', 'JS Dependencies', 'unload')
    // })

    // // Google Ads (piggyback on the global setup above)
    // gtag(`config`, `AW-797474007`)

    // // Google Ads phone snippet
    // gtag('config', 'AW-797474007/SOWoCIX71YkBENf5ofwC', {
    //   phone_conversion_number: '+18448575464'
    // })
    // }
    // )
  }

  // Fetch polyfill for FormNetlify (IE, Opera Mini, Blackberry Browser, IE Mobile)
  if (!loadjs.isDefined(`fetch`)) {
    if (typeof window.fetch === `undefined`) {
      loadjs(
        `https://cdnjs.cloudflare.com/ajax/libs/fetch/2.0.4/fetch.min.js`,
        `fetch`
      )
    }
  }
}
